module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<header>\r\n    <ul class="left">\r\n        <li>\r\n            <a href="javascript:;" id="shop">Shop</a>\r\n            <ul class="shop-nav nav" style="display:none;">\r\n                <li>\r\n                    <a href="list_shop.html">\r\n                        <img src="' +
((__t = ( require('./images/nav-shop1.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </a>\r\n                    <h3><a href="list_shop.html">Best Sellers</a></h3>\r\n                </li>\r\n                <li>\r\n                    <a href="list_shop.html">\r\n                        <img src="' +
((__t = ( require('./images/nav-shop2.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </a>\r\n                    <h3><a href="list_shop.html">Miracle Pearls</a></h3>\r\n                </li>\r\n                <li>\r\n                    <a href="list_shop.html">\r\n                        <img src="' +
((__t = ( require('./images/nav-shop3.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </a>\r\n                    <h3><a href="list_shop.html">Dewy Skin</a></h3>\r\n                </li>\r\n                <li>\r\n                    <a href="list_shop.html">\r\n                        <img src="' +
((__t = ( require('./images/nav-shop4.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </a>\r\n                    <h3><a href="list_shop.html">Intensive Care</a></h3>\r\n                </li>\r\n                <li>\r\n                    <a href="list_shop.html">\r\n                        <img src="' +
((__t = ( require('./images/nav-shop5.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </a>\r\n                    <h3><a href="list_shop.html">Body Care</a></h3>\r\n                </li>\r\n                <li>\r\n                    <a href="list_shop.html">\r\n                        <img src="' +
((__t = ( require('./images/nav-shop6.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </a>\r\n                    <h3><a href="list_shop.html">Beauty Drink</a></h3>\r\n                </li>\r\n            </ul>\r\n        </li>\r\n        <li>\r\n            <a href="javascript:;" id="learn">Learn</a>\r\n            <ul class="learn-nav nav" style="display:none;">\r\n                <li>\r\n                    <a href="about_mission.html">\r\n                        <img src="' +
((__t = ( require('./images/nav-learn1.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </a>\r\n                    <h3><a href="about_mission.html">Who are we</a></h3>\r\n                </li>\r\n                <li>\r\n                    <a href="about_sustainability.html">\r\n                        <img src="' +
((__t = ( require('./images/nav-learn2.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </a>\r\n                    <h3><a href="about_sustainability.html">Sustainability</a></h3>\r\n                </li>\r\n                <li>\r\n                    <a href="about_efficacious.html">\r\n                        <img src="' +
((__t = ( require('./images/nav-learn3.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </a>\r\n                    <h3><a href="about_efficacious.html">Efficacious</a></h3>\r\n                </li>\r\n                <li>\r\n                    <a href="about_offline.html">\r\n                        <img src="' +
((__t = ( require('./images/nav-learn4.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </a>\r\n                    <h3><a href="about_offline.html">offline</a></h3>\r\n                </li>\r\n                <li>\r\n                    <a href="about_online.html">\r\n                        <img src="' +
((__t = ( require('./images/nav-learn5.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </a>\r\n                    <h3><a href="about_online.html">online</a></h3>\r\n                </li>\r\n                <li>\r\n                    <a href="about_story.html">\r\n                        <img src="' +
((__t = ( require('./images/nav-learn6.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </a>\r\n                    <h3><a href="about_story.html">Our Story</a></h3>\r\n                </li>\r\n                <li>\r\n                    <a href="about_reviews.html">\r\n                        <img src="' +
((__t = ( require('./images/nav-learn7.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </a>\r\n                    <h3><a href="about_reviews.html">Reviews</a></h3>\r\n                </li>\r\n                <li>\r\n                    <a href="about_contact.html">\r\n                        <img src="' +
((__t = ( require('./images/nav-learn8.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </a>\r\n                    <h3><a href="about_contact.html">Contact Us</a></h3>\r\n                </li>\r\n            </ul>\r\n        </li>\r\n        \r\n    </ul>\r\n    <div class="logo">\r\n        <a href="index.html"><img src="' +
((__t = ( require('./images/logo.png') )) == null ? '' : __t) +
'" alt=""></a>\r\n    </div>\r\n    <div class="right">\r\n        <a href="javascript:;" class="language">CN</a>\r\n        <a href="javascript:;" class="shop">\r\n            <i class="iconfont icon-gouwu"></i>\r\n        </a>\r\n    </div>\r\n</header>\r\n';

}
return __p
}