import './index.scss'

var mouseover_tid = [];
var mouseout_tid = [];
jQuery(document).ready(function () {
    jQuery(".left li").each(function (index) {
        jQuery(this).hover(
            // 鼠标进入
            function () {
                var _self = this;
                // 停止卷起事件
                clearTimeout(mouseout_tid[ index ]);
                // 当鼠标进入超过 0.2 秒, 展开菜单, 并记录到线程 ID 中
                mouseover_tid[ index ] = setTimeout(function () {
                    jQuery(_self).children(".shop-nav").slideDown(300);
                    jQuery(_self).children(".learn-nav").slideDown(300);
                }, 201);
            },
            // 鼠标离开
            function () {
                var _self = this;
                // 停止展开事件
                clearTimeout(mouseover_tid[ index ]);
                // 当鼠标离开超过 0.2 秒, 卷起菜单, 并记录到线程 ID 中
                mouseout_tid[ index ] = setTimeout(function () {
                    jQuery(_self).children(".shop-nav").slideUp(300);
                    jQuery(_self).children(".learn-nav").slideUp(300);
                }, 201);
            }
        );
    });
});

//页面滚动监听
var beforeScroH = $(document).scrollTop();
$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度
    if (scroH > 100){
        $("header").addClass('head1');
    }else{
        $("header").removeClass('head1');
    }

  //判断页面是上滚还是下滚
  var delta = scroH - beforeScroH;
//   if (window.screen.width > 1200){
    if (delta > 0 && scroH > viewH / 3) {
        $("header").addClass('hide');
    }
    if (delta < 0) {
        if ($('.index-page .section4').length > 0){
            if (scroH > $('.index-page .section4').offset().top && scroH < $('.index-page .section4').offset().top+$('.index-page .section4').height()){
                $("header").addClass('hide');
            }else{
                $("header").removeClass('hide');
            }
        }else{
            $("header").removeClass('hide');
        }
    }
//   }
  beforeScroH = scroH;
});